import { useAppContext } from "fusion-context";
import { useContent } from "fusion-content";
import GridItem from "~/components/layouts/utilities/grid-item.jsx";

import { defaultAdType, InTableAdPropTypes } from "~/proptypes/in-table-ad";

import { ZeusAd } from "~/components/features/fronts/zeus/default";

const InTableAd = ({
  customFields: { adType = defaultAdType },
  globalContent
}) => {
  const { metaValue = () => "homepage", isAdmin } = useAppContext();
  const contentType = metaValue("contentType");

  const maxAdConfig =
    useContent({
      source: "zeus-max-ad-config"
    }) || null;

  return (
    <GridItem
      featureLabel="In-Table Ad"
      placementRequirements={{ inMultiTableChain: true, tables: ["tableAd1"] }}
      className="is-ad"
    >
      {!isAdmin && !maxAdConfig ? null : (
        <ZeusAd
          adType={adType}
          isAdmin={isAdmin}
          maxAdConfig={maxAdConfig}
          globalContent={globalContent}
          contentType={contentType}
        />
      )}
    </GridItem>
  );
};

InTableAd.propTypes = InTableAdPropTypes;

export default InTableAd;
